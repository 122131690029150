import { loadStripe } from '@stripe/stripe-js';

const mountStripeElements = async () => {
  if (!document.getElementById('stripe-card-element')) return;
  const stripe = await loadStripe(process.env.STRIPE_PUBLISHABLE_KEY);
  const elements = stripe.elements();

  // Custom styling can be passed to options when creating an Element.
  const style = {
    base: {
      fontSize: '16px',
      '::placeholder': {
        color: '#d8d8d8',
        textTransform: 'capitalize',
      }
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a'
    }
  };

  var card = elements.create('card', { style });
  card.mount('#stripe-card-element');

  card.on('change', function(event) {
    if(event.complete){
      $(".next_btn").removeAttr("disabled")
    }else{
      $(".next_btn").attr("disabled", true)
    }
  });

  $("#subscription_type_bill_to_account").on("click",function(){
    card.unmount('#stripe-card-element');
    $(".card_fields").hide()
    // $(".subscription_list").hide()
    $(".next_btn").removeAttr("disabled")
    document.querySelectorAll('.stripe-card-form').forEach((f) => f.removeEventListener('submit', formEventListener));
  })

  $(".free_subscription_div").on("click",function(){
    $("#subscription_type").val("bill_to_account")
    card.unmount('#stripe-card-element');
    $(".card_fields").hide()
    $(".next_btn").removeAttr("disabled")
    document.querySelectorAll('.stripe-card-form').forEach((f) => f.removeEventListener('submit', formEventListener));
  })

  $(".card_subscriptions").on("click",function(){
    if(!$('#subscription_type_bill_to_account').is(':checked')) { 
    $("#subscription_type").val("credit_card")
    card.mount('#stripe-card-element');
    $(".card_fields").show()
    document.querySelectorAll('.stripe-card-form').forEach((f) => f.addEventListener('submit', formEventListener));
    }
  })



  $("#subscription_type_credit_card").on("click",function(){
    // if($(".free_subscription_div").length > 0){ //Refer LP-351 before modifying this line
    card.mount('#stripe-card-element');
    $(".card_fields").show()
    // $(".subscription_list").show()
    document.querySelectorAll('.stripe-card-form').forEach((f) => f.addEventListener('submit', formEventListener));
    // }
  })

  // Handle real-time validation errors from the card Element.
  card.on('change', function(event) {
    var displayError = document.getElementById('stripe-card-errors');
    if (event.error) {
      displayError.textContent = event.error.message;
    } else {
      displayError.textContent = '';
    }
    document.querySelectorAll('#save_new_payment_card').forEach((el) => el.disabled = false);
    // document.getElementById('save_new_payment_card').disabled = false;
  });

  // Handle form submission.
  const formEventListener = function(event) {
    // Only process the new stripe card number if the user didn't select an existing card
    if ($(event.target).serializeArray().find((el) => el.name == 'payment_method_key')?.value) return;

    event.preventDefault();

    stripe.createToken(card).then(function(result) {
      if (result.error) {
        // Inform the user if there was an error.
        var errorElement = document.getElementById('stripe-card-errors');
        errorElement.textContent = result.error.message;
      } else {
        // Send the token to your server.
        stripeTokenHandler(result.token, event.target);
      }
    });
  };
  // var form = document.getElementById('new_payment_card');
  document.querySelectorAll('.stripe-card-form').forEach((f) => f.addEventListener('submit', formEventListener));
  // form.addEventListener('submit', formEventListener);

  if($(".free_subscription_div").length > 0){
    card.unmount('#stripe-card-element');
    $(".card_fields").hide()
    $(".next_btn").removeAttr("disabled")
    document.querySelectorAll('.stripe-card-form').forEach((f) => f.removeEventListener('submit', formEventListener));
  }

  // Submit the form with the token ID.
  function stripeTokenHandler(token, form) {
    // Insert the token ID into the form so it gets submitted to the server
    var hiddenInput = document.createElement('input');
    hiddenInput.setAttribute('type', 'hidden');
    hiddenInput.setAttribute('name', 'stripeToken');
    hiddenInput.setAttribute('value', token.id);
    form.appendChild(hiddenInput);

    // Submit the form
    form.submit();
  }
}

$(document).on('ready turbolinks:load', () => {
  mountStripeElements();
});
