import { throttle } from "throttle-debounce";

window.addEventListener('turbolinks:load', () => {

  const throttleLoad = throttle(200, () => {
    let more_crews_url = $('.pagination a[rel="next"]').attr('href');

    if (more_crews_url && $(window).scrollTop() > $(document).height() - $(window).height() - ($('.footer').height() / 2)) {
      $('#crew_pagination').prepend('<h2 class="text-primary text-center mt-3"><i class="fas fa-spinner fa-pulse"></i></h2>')
      $.getScript(more_crews_url);
    }
  })

  window.crewThrottleLoad = throttleLoad;

  if ($('#infinite-scrolling').length) {
    $(window).off('scroll');
    $(window).on('scroll', throttleLoad);
  }

  // $('body').on('change', '#crews\\/organization_search :input, #crews\\/organization_search select', function(e) {
  //   let form = $(this).closest('form');
  //   $.get(form.attr('action'), form.serialize(), $.noop, 'script');
  // });

});
