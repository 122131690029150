window.addEventListener('turbolinks:load', () => {
  $("input[name=payment_method_key]").on('change', (e) => {
    if (e.target.value) { // Existing payment card is selected
      $('#new_card_fields').hide();
    }
    else {
      $('#new_card_fields').show();
    }
  });
});
