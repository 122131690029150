/**
 * trackingEvents to leverage analytics integrations
 */
window.addEventListener('turbolinks:load', () => {

    // Send crews_reg_successful event when new crew submits registration form
    $("#new_crew[action='/crews']").submit(function(event) {
        window.localStorage.setItem('crew_registered', 'true')
    });
    if (window.location.pathname === '/' && window.localStorage.getItem('crew_registered') === 'true') {
        dataLayer.push({'event':'crews_reg_successful'});
        window.localStorage.removeItem('crew_registered')
    }

    // Send contractors_reg_successful event when new contractor submits registration form
    $("#new_contractor[action='/contractors']").submit(function(event) {
        window.localStorage.setItem('contractor_registered', 'true')
    });
    if (window.location.pathname === '/' && window.localStorage.getItem('contractor_registered') === 'true') {
        dataLayer.push({'event':'contractors_reg_successful'});
        window.localStorage.removeItem('contractor_registered')
    }

    // Find Crews - Search Events
    if (window.location.pathname === '/crews') {
        var contractorUser = $('.dash-title')[0].innerText

        $('form[action="/crews"] .form-group').not('.form-group .form-group').each(function(el){
            var searchField = $(this).find('strong label').text()
            if (!searchField) {
                searchField = $(this).find('strong span').first().text()
            }

            // Checkbox Clicks
            $(this).find('input[type="checkbox"]').each(function(){
                var fieldValue = $(this).next().text()
                $(this).on('change', function (e) {
                    dataLayer.push({
                        event: 'crew_search_filter',
                        value: {
                            [searchField]: {
                                [fieldValue]: $(this)[0].checked ? 'selected' : 'unselected'
                            },
                            contractor: contractorUser
                        }
                    })
                })
            })

            // Radio Button Clicks
            $(this).find('input[type="radio"]').each(function(){
                var fieldValue = $(this).next().text()
                $(this).on('change', function (e) {
                    dataLayer.push({
                        event: 'crew_search_filter',
                        value: {
                            [searchField]: fieldValue,
                            contractor: contractorUser
                        }
                    })
                })
            })

            // Selector Fields
            $(this).find('select').each(function(){
                var fieldValue = $(this).prev().text()
                if (!fieldValue) {
                    fieldValue = $(this).parent().prev().find('label').text()
                }
                $(this).on('change', function (e) {
                    dataLayer.push({
                        event: 'crew_search_filter',
                        value: {
                            [searchField]: {
                                [fieldValue]: e.target.value
                            },
                            contractor: contractorUser
                        }
                    })
                })
            })

            // Input search Fields
            $(this).find('input[type="search"').each(function(){
                var fieldValue = $(this).prev().text()
                if (!fieldValue) {
                    fieldValue = $(this).parent().prev().find('label').text()
                }
                $(this).on('change', function (e) {
                    dataLayer.push({
                        event: 'crew_search_filter',
                        value: {
                            [searchField]: {
                                [fieldValue]: e.target.value
                            },
                            contractor: contractorUser
                        }
                    })
                })
            })
        })
    }

    // Message Tracking
    if (window.location.pathname === '/crews') {
        var contractorUser = $('.dash-title')[0].innerText

        $('form[action="/direct_messages/new"] .card').each(function(el){
            var companyToContact = $(this).find('.card-title')[0].innerText

            // Checkbox Clicks
            $(this).find('input[type="checkbox"]').on('change', function (e) {
                dataLayer.push({
                    event: 'crew_search_select_to_contact',
                    value: {
                        [contractorUser]: {
                            [companyToContact]: $(this)[0].checked ? 'selected' : 'unselected',
                            timeSelected: Date()
                        }
                    }
                })
            })
        })
    }
    if (window.location.pathname.indexOf('/direct_messages/new') > -1) {
        $('input[type="submit"]').on('click', function () {
            var contractor = $('.col-6')[0].innerText
            let crews = []
            $('.card-body .row.mb-3 .col-auto').each(function(){
                crews.push($(this)[0].innerText)
            })

            dataLayer.push({
                event: 'find_crews_direct_message',
                value: {
                    contractor: contractor,
                    crews: crews,
                    message: $('textarea')[0].value,
                    time: Date()
                }
            })
        })
    }
})